import { initialize, changeUser, openSession, automaticallyShowInAppMessages } from '@braze/web-sdk';
import { startBugsnag } from 'utils/bugsnag';
import '../../assets/javascripts/datadome';
import './branch';

// Intl.Locale polyfill for Safari 13 and below
import '@formatjs/intl-locale/polyfill';

const data = global.__AT_DATA__;

if (data) {
  if (data.BRAZE_KEY) {
    initialize(data.BRAZE_KEY, {
      baseUrl: 'https://sdk.iad-03.braze.com',
      allowUserSuppliedJavascript: true
    });

    automaticallyShowInAppMessages();

    if (data.store.user) {
      changeUser(String(data.store.user.id));
    }

    openSession();
  }

  startBugsnag();
}

// see https://github.com/aFarkas/lazysizes#user-content-js-api---options
window.lazySizesConfig = {
  expand: 10 // size buffer to start loading images before they reach viewport
};

// add simple support for background images:
document.addEventListener('lazybeforeunveil', e => {
  const target = e.target as HTMLElement;
  let bg = target.getAttribute('data-bg');
  if (bg) {
    if (bg.indexOf('url') < 0) {
      bg = `url('${bg}')`;
    }
    if (bg.slice(-1) === ')') {
      // if last character is ')' assume that we're just updated background image
      target.style.backgroundImage = bg;
    } else {
      // otherwise, update the entire background css
      target.style.background = bg;
    }
  }
});

import('lazysizes');
