import { Permission } from 'types/Context';

type Args = {
  permission: Permission;
};

export default function hasPermission({ permission }: Args) {
  if (__AT_DATA__.store?.user?.permissions?.includes(permission)) {
    return true;
  }

  // Fall back to legacy admin check.
  return Boolean(__AT_DATA__.store?.user?.admin);
}
